// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import SEO from '../../../components/seo'
import LandingpageNav from '../../../components/Landingpage/LandingpageNav'
import CustomContactBtn from '../../../components/Landingpage/CustomContactBtn'
import Landingpage from '../../../components/Landingpage'
import messages from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './headless-cms.scss'
import baseLandingpageMessages from '../../../components/Landingpage/Landingpage.lang'
// import caseMessages from '../../../components/Landingpage/messages/HeadlessCms/HeadlessCms.lang'

import { StaticImage } from 'gatsby-plugin-image'

/* eslint-disable max-len */
const heroImg = (
  <StaticImage src="../../../images/landingpage/heroteaser_background.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgSmall1 = (
  <StaticImage src="../../../images/landingpage/discussion-meeting.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgSmall2 = (
  <StaticImage src="../../../images/landingpage/men-explaining-laptop.jpg" alt="" objectFit="cover" className="image" />
)
const benefitsImg = (
  <StaticImage src="../../../images/landingpage/guy-staring.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgBig = (
  <StaticImage src="../../../images/landingpage/happy-man-success.jpg" alt="" objectFit="cover" className="image" />
)
const imgStrapi = (
  <StaticImage src="../../../images/landingpage/strapi.jpg" alt="" objectFit="cover" className="image" />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const data = {
  heroTeaser: {
    img: heroImg,
    title: baseLandingpageMessages.heroTitle,
    subtitle: baseLandingpageMessages.heroSubtitle
  },
  descriptionTeaser: {
    title: baseLandingpageMessages.descriptionTitle,
    text: baseLandingpageMessages.descriptionText,
    displayButton: true,
    button: baseLandingpageMessages.descriptionButton,
    imgBig: descriptionImgBig,
    imgSmall1: descriptionImgSmall1,
    imgSmall2: descriptionImgSmall2
  },
  valueBox: {
    title: baseLandingpageMessages.valueBoxTitle,
    subtitle: baseLandingpageMessages.valueBoxSubtitle,
    title1: baseLandingpageMessages.valueBoxTitle1,
    title2: baseLandingpageMessages.valueBoxTitle2,
    title3: baseLandingpageMessages.valueBoxTitle3,
    values1: [
      baseLandingpageMessages.productValues1A,
      baseLandingpageMessages.productValues1B,
      baseLandingpageMessages.productValues1C
    ],
    values2: [
      baseLandingpageMessages.productValues2A,
      baseLandingpageMessages.productValues2B,
      baseLandingpageMessages.productValues2C
    ],
    values3: [
      baseLandingpageMessages.productValues3A,
      baseLandingpageMessages.productValues3B,
      baseLandingpageMessages.productValues3C
    ]
  },
  benefitsTeaser: {
    img: benefitsImg,
    title: baseLandingpageMessages.benefitsTitle,
    text: baseLandingpageMessages.benefitsText,
    button: baseLandingpageMessages.benefitsButton,
    list: [
      baseLandingpageMessages.benefitsItemA,
      baseLandingpageMessages.benefitsItemB,
      baseLandingpageMessages.benefitsItemC,
      baseLandingpageMessages.benefitsItemD,
      baseLandingpageMessages.benefitsItemE,
      baseLandingpageMessages.benefitsItemF
    ]
  },
  strapiTeaser: {
    title: baseLandingpageMessages.strapiTitle,
    text: baseLandingpageMessages.strapiText,
    img: imgStrapi
  },
  contact: {
    title: baseLandingpageMessages.contactTitle,
    subtitle: baseLandingpageMessages.contactSubtitle,
    headline: baseLandingpageMessages.contactheadline
  }
}

const LandingHeadlessCms = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout
      footer={<Footer />}
      header={<Header withTeaser hasCustomNav customNav={<LandingpageNav />} contactBtn={<CustomContactBtn />} />}
    >
      <SEO
        title={formatMessage(messages.TitleHeadlessCms)}
        description={formatMessage(metaDescription.headlesscmsDescription)}
        lang={locale}
      />
      <Landingpage data={data} styles={styles.landing} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(LandingHeadlessCms)
